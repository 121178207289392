import logo from './logo.svg';
import './App.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import BottomNavigation from '@mui/material/BottomNavigation';
import Paper from '@mui/material/Paper';

import React, { useState, useEffect } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { Helmet } from "react-helmet";

import StickyFooter from './StickyFooter';
import Container from '@mui/material/Container';
import SearchAppBar from "./components/AppBar"
import BasicModal from './components/Modal';

import ResponsiveDrawer from './components/AppBar2';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  RedirectToSignIn,
  SignIn,
  SignUp,
  UserButton,
  useUser,
  useAuth,
} from "@clerk/clerk-react";
import { BrowserRouter, Route, Routes, useNavigate, currentUser } from "react-router-dom";

{/*import { currentUser } from '@clerk/nextjs';*/}

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

function PublicHomePage() {
  return (
    <div>
      <h1>Welcome to f(x)</h1>
      {/*<a href="/protected"><Button>Get Started</Button></a>*/}
      <a href="/protected"><Button>Get Started</Button></a>
      {/*<a href="/protected">Go to Protected Zone</a>*/}
    </div>
  );
}

function PublicPage() {
  return (
    <>
      <h1>Public landing page</h1>
      <a href="/protected">Go to protected page</a>
    </>
  );
}

function ProtectedPage() {
  return (
    <>
      <h1>Protected page</h1>
      <UserButton />
    </>
  );
}

function UserExample() {
  const { isLoaded, isSignedIn, user } = useUser();

  if (!isLoaded || !isSignedIn) {
  return "";{/*null;*/}
  }

  {/*user.firstName*/}
  return <div>Hello, {user.primaryEmailAddress.emailAddress} welcome to Clerk</div>;
}

function UserEmailAddress() {
  const { isLoaded, isSignedIn, user } = useUser();

  if (!isLoaded || !isSignedIn) {
    return "";{/*null;*/}
  }
  return user.primaryEmailAddress.emailAddress;
}

function UserFirstName() {
  const { isLoaded, isSignedIn, user } = useUser();

  if (!isLoaded || !isSignedIn) {
    return "";{/*null;*/}
  }
  return user.firstName;
}

function Greeting(props) {
  const isSubbed = props.isSubscribed;
  if (isSubbed) {
    return <SubscriberGreeting />;
  }
  return <NonSubscriberGreeting />;
}

function SubscriberGreeting() {
  const { isLoaded, isSignedIn, user } = useUser();
  var userEmailAddr = UserEmailAddress();
  var userFname = UserFirstName();

  return (
    <h3>You are subscribed {userFname}! ({userEmailAddr})</h3>
  );
}

function SubDiv1(props) {
  const isSubbed = props.isSubscribed;
  if (isSubbed) {
    return <SubscriberDiv1 />;
  }
  return <NonSubscriberDiv />;
}

function SubscriberDiv1() {

  return (
    <div>
      <Greeting isSubscribed={true}/>
      <p>Hey Subscriber</p>
    </div>
  )
}

function NonSubscriberDiv() {
  const { isLoaded, isSignedIn, user } = useUser();
  var userEmailAddr = UserEmailAddress();
  var userFname = UserFirstName();

  return (
    <div>
      <Greeting isSubscribed={false}/>
      <p>Sorry you are not subscribed</p>
      <a href={"https://buy.stripe.com/test_7sIcNP14N7xD6qs144?prefilled_email=" + userEmailAddr}><Button>Subscribe Here!</Button></a>
    </div>
  )
}

function NonSubscriberGreeting() {
  const { isLoaded, isSignedIn, user } = useUser();
  var userEmailAddr = UserEmailAddress();
  var userFname = UserFirstName();

  return (
    <h3>You are not subscribed {userFname}! ({userEmailAddr})</h3>
  );
}

function ProtectedUserPortalPage() {
  const { isLoaded, isSignedIn, user } = useUser();
  var userEmailAddr = UserEmailAddress();
  var userFname = UserFirstName();

  const [textOutput, setTextOutput] = useState('');
  const handleTextOutputChange = event => {
      {/*console.log('Saving value');
  console.log(event.target.value);*/}
      setTextOutput(event.target.value);
  };

  var userIsSubscribed = false;
  const authenticatedFetch = useFetch();

  useEffect(()=>{
      // do stuff here...
      {/*alert('Hello!');*/}
    var checkSubURL = 'https://funquiz.app/checksubstatus2/?email_address=' + userEmailAddr;
    // var checkSubURL = 'https://funquiz.app/checksubstatus2/verify-jwt';

    authenticatedFetch(checkSubURL)
    .then(response => response.text()
    )
    .then((response) => {
      var dresp = response;
      var drespArr =  dresp.split(' '); {/* [emailaddr],[True/False] */}
      if (drespArr.length > 1)
      {
        //setTextOutput(dresp);
        {/*setTextOutput(drespArr[1]);*/}
        if (drespArr[1] === "True")
        {
          userIsSubscribed = true;
          {/*setTextOutput("User is subscribed!");*/}
        }
        else if (drespArr[1] === "False")
        {
          userIsSubscribed = false;
          {/*setTextOutput("User is not subscribed!");*/}
        }
      }
      else 
      {
        {/*setTextOutput("bad resp");*/}
      }
      {console.log(response);}
    })
    .catch(error => {
      // Handle the error
      console.error('Error:', error);
    });
  }, []) 

  return (
    <>
      <h1>Protected User Portal X</h1>
      <UserButton />
      {/*<Greeting isSubscribed={userIsSubscribed}/>*/}
      {/*<h3>Are you subscribed {userFname}? ({userEmailAddr})</h3>*/}
      {/*non test 1: https://buy.stripe.com/28o6ph1x4gjY3wA6oo, test 2: https://buy.stripe.com/test_7sIcNP14N7xD6qs144*/}
      {/*<a href={"https://buy.stripe.com/test_7sIcNP14N7xD6qs144?prefilled_email=" + userEmailAddr}><Button>Subscribe Here!</Button></a>*/}
      <SubDiv1 isSubscribed={userIsSubscribed}/>
      {/*<OutputForm />*/}
      <p>{textOutput}</p>
    </>
  );
}

function ClerkProviderWithRoutes() {
  const navigate = useNavigate();

  return (
    <ClerkProvider
      publishableKey={clerkPubKey}
      navigate={(to) => navigate(to)}
    >
      <Routes>
        <Route 
          path="/" 
          element={<PublicHomePage />} 
        />
        <Route
          path="/sign-in/*"
          element={<SignIn routing="path" path="/sign-in" />}
        />
        <Route
          path="/sign-up/*"
          element={<SignUp routing="path" path="/sign-up" />}
        />
        <Route
          path="/protected"
          element={
          <>
            <SignedIn>
              <ProtectedUserPortalPage />
            </SignedIn>
             <SignedOut>
              <RedirectToSignIn /> {/*maybe redirect to sign up*/}
           </SignedOut>
          </>
          }
        />
      </Routes>
    </ClerkProvider>
  );
}

function useFetch() {
  const { getToken } = useAuth();
  const authenticatedFetch = async (...args) => {
    return fetch(...args, {
      headers: { Authorization: `Bearer ${await getToken()}` }
    });//.then(res => res.json());
  };
  return authenticatedFetch;
}

function OutputForm() {
  const [textOutput, setTextOutput] = useState('');
  const handleTextOutputChange = event => {
      {/*console.log('Saving value');
  console.log(event.target.value);*/}
      setTextOutput(event.target.value);
  };

  const outputProps = {
    outputProps: {
      style: { textAlign: 'center' },
    },
  };

  {/*possible cors bug solution: remove final '/' from 
    url so that we use https://funquiz.app/pyweb
    instead of https://funquiz.app/pyweb/
  
    check out https://cors-test.codehappy.dev
  
    hmm still not working, at least with localhost origin*/}
  {/*JSON.stringify*/}

  // Call the useFetch hook to get the authenticatedFetch function
  const authenticatedFetch = useFetch();
  
  const { isLoaded, isSignedIn, user } = useUser();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  var userEmailAddr = UserEmailAddress();
  var checkSubURL = 'https://funquiz.app/checksubstatus2/?email_address=' + userEmailAddr;

  useEffect(()=>{
    // do stuff here...
    authenticatedFetch(checkSubURL)
    .then(response => response.text()
    )
    .then((response) => {
      { console.log(response);}
    })
    .catch(error => {
      // Handle the error
      console.error('Error:', error);
    });
  }, []) 

  return (
    <div id="formx">
      <Box marginTop={5} marginBottom={5}>
      <p>x</p>
      <p>{textOutput}</p>
      </Box>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <ClerkProviderWithRoutes />
    </BrowserRouter>
  );
}


export default App;
